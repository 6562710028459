<!-- 体系库一级 -->
<template>
  <div class="bgc">
    <div v-if="tab == 1">
      <div class="top">
        <h3>体系库</h3>
        <div style="display: flex">
          <el-select
            v-model="value"
            placeholder="按状态筛选"
            size="medium"
            clearable
            @change="change"
          >
            <el-option
              v-for="item in options"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
          <el-input
            v-model="input"
            placeholder="按关键词进行查找"
            size="medium"
            @input="change1"
          ></el-input>
          <el-button
            type="primary"
            style="background-color: #29303e; border: 1px solid #29303e"
            size="small"
            @click="change1"
          >
            查询
          </el-button>
          <el-button
            type="primary"
            style="background-color: #29303e; border: 1px solid #29303e; color: white"
            plain
            size="small"
            @click="configuration"
          >
            生成配置
          </el-button>
          <!-- <el-button type="primary" plain size="small" @click="addSystem">新建体系</el-button> -->
        </div>
      </div>
      <div style="margin-bottom: 30px">
        <el-table
          :data="tableData"
          style="width: 100%; color: #000"
          border
          :header-cell-style="{ background: '#F2F2F2' }"
          align="center"
        >
          <el-table-column
            type="index"
            :index="indexMethod"
            label="序号"
            width="100"
            align="center"
          ></el-table-column>
          <el-table-column prop="title" label="分类名称" align="center">
            <template #default="scope">
              <div style="overflow: hidden; text-overflow: ellipsis; white-space: nowrap">
                {{ scope.row.title }}
              </div>
            </template>
          </el-table-column>
          <el-table-column prop="system_num" label="子分类数量（个）" align="center">
            <template #default="scope">
              <div>
                {{ scope.row.system_num }}
              </div>
            </template>
          </el-table-column>
          <!-- <el-table-column prop="picture" label="图片" align="center">
                        <template #default='{ row }'>
                            <el-image v-if="row.picture" style="width: 50px; height: 50px" :src="row.picture"
                                :preview-src-list="[row.picture]" fit="cover" />
                        </template>
                    </el-table-column> -->
          <el-table-column prop="create_time" label="创建时间" align="center"></el-table-column>
          <el-table-column prop="status_str" label="状态" width="100" align="center">
            <template #default="{ row }">
              <el-tag v-if="row.status_str === '使用中'">使用中</el-tag>
              <el-tag v-else class="ml-2" type="warning">停用中</el-tag>
            </template>
          </el-table-column>
          <el-table-column prop="address" label="操作" width="200" align="center">
            <template #default="{ row }">
              <el-button
                type="text"
                @click="changeStatus(row.id)"
                v-if="row.status_str === '使用中'"
              >
                停用
              </el-button>
              <el-button type="text" @click="changeStatus(row.id)" v-else>启用</el-button>
              <!--<el-button type="text" @click="edit(row.id)">编辑</el-button>
                            <el-button type="text" @click="del(row.id)">删除</el-button> -->
              <el-button type="text" @click="tabTwoList(row.id, row.tag)">子体系</el-button>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div
        style="
          text-align: center;
          margin-top: 30px;
          position: absolute;
          bottom: 10px;
          left: 50%;
          transform: translateX(-50%);
          background-color: #fff;
        "
      >
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="page.page"
          :page-sizes="[10, 30, 40, 50]"
          :page-size="page.limit"
          layout="total, sizes, prev, pager, next, jumper"
          :total="page.total"
        ></el-pagination>
      </div>
      <!-- 新建体系 -->
      <!-- <el-dialog v-model="dialogVisible" width="400px" :before-close="removeID" title="新建体系" center
                style="padding: 0 !important">
                <el-form :model="form" label-width="100px" label-position="left" v-loading="addSystemLoading">
                    <el-form-item label="体系名称">
                        <el-input v-model="form.name" style="width: 235px" />
                    </el-form-item>
                    <el-form-item label="包含标签">
                        <el-select v-model="labelValue" multiple filterable allow-create default-first-option
                            :reserve-keyword="false" placeholder="请选择标签" style="width: 235px">
                            <el-option v-for="item in labelList" :key="item.value" :label="item.value"
                                :value="item.address" />
                        </el-select>
                    </el-form-item>
                </el-form>
                <template #footer>
                    <span class="dialog-footer">
                        <div style="padding: 0 10px">
                            <el-button @click="removeID" style="width: 150px">返回</el-button>
                            <el-button type="primary" @click="conserve" style="width: 150px">保存</el-button>
                        </div>
                    </span>
                </template>
            </el-dialog> -->
    </div>
    <div v-if="tab == 2">
      <system-storeroom :tabID="tabID" :tabTag="tabTag"></system-storeroom>
    </div>
  </div>
</template>

<script>
import {
  getsystemslassifylist,
  setsystemclassifystatus,
  matchinglist,
} from '@/common/js/systemList.js';
import SystemStoreroom from './systemStoreroom.vue';
export default {
  // 组件名称
  name: 'systemOneList',
  // 组件参数 接收来自父组件的数据
  props: {},
  // 局部注册的组件
  components: { SystemStoreroom },
  // 组件状态值
  data() {
    return {
      form: {
        name: '',
      },
      options: [
        {
          value: 1,
          label: '启用中',
        },
        {
          value: 0,
          label: '停用中',
        },
      ],
      value: '',
      input: '',
      tableData: [],
      dialogVisible: false,
      page: {
        page: 1,
        limit: 10,
        total: 0,
      },
      labelList: [],
      labelValue: '',
      brandInput: '',
      id: '',
      addSystemLoading: true,
      tab: 1,
      tabID: '',
      tabTag: '',
    };
  },
  // 计算属性
  computed: {},
  // 侦听器
  watch: {
    tab() {
      if (this.tab == 1) {
        (this.tabID = ''), (this.tabTag = '');
      }
    },
  },
  // 组件方法
  methods: {
    // 跳转二级列表
    tabTwoList(tabID, tabTag) {
      this.tabID = tabID;
      this.tabTag = tabTag;
      this.tab = 2;
    },
    // 列表详情
    getList() {
      let dt = {
        limit: this.page.limit,
        page: this.page.page,
        keywords: this.input,
        status: this.value,
      };
      getsystemslassifylist(dt).then((res) => {
        let {
          code,
          result: { data, total },
        } = res;
        if (code === 200) {
          this.tableData = data;
          this.page.total = total;
        }
      });
    },
    // 状态筛选
    change() {
      this.page.page = 1;
      this.getList();
    },
    // 输入关键词搜索
    change1() {
      this.page.page = 1;
      this.getList();
    },
    // 修改状态
    changeStatus(id) {
      setsystemclassifystatus({ id }).then((res) => {
        let { code, msg } = res;
        if (code === 200) {
          this.getList();
          this.$message.success(msg);
        }
      });
    },
    //生成配置
    configuration() {
      matchinglist().then((res) => {
        let { code } = res;
        if (code == 200) {
          this.$message.success('生成配置完成');
        }
      });
    },
    // // 删除
    // del(id) {
    //     setsystemdel({ id }).then(res => {
    //         let { code, msg } = res
    //         if (code === 200) {
    //             this.$message.success(msg)
    //             this.getList()
    //         }
    //     })
    // },
    // // 编辑
    // edit(id) {
    //     this.dialogVisible = true
    //     this.addSystemLoading = true
    //     this.id = id
    //     this.addSystem()
    //     setsysteminfo({ id }).then(res => {
    //         console.log(res);
    //         let { code, result: { title, middle_tag, brand_id } } = res
    //         if (code === 200) {
    //             this.form.name = title
    //             this.labelValue = middle_tag.map(item => item.t_id)
    //             this.brandInput = brand_id === 0 ? '' : brand_id
    //         }
    //     })
    // },
    // // 新建体系
    // addSystem() {
    //     this.form.name = '',
    //         this.labelValue = [],
    //         this.brandInput = '',
    //         this.dialogVisible = true
    //     this.addSystemLoading = true
    //     getcanselecttag().then(res => {
    //         let { code, result } = res
    //         if (code === 200) {
    //             this.labelList = result
    //         }
    //     })
    // },
    // // 保存
    // conserve() {
    //     // this.labelValue = this.labelValue.map(item => item.t_id)
    //     let dt = {
    //         title: this.form.name,
    //         tag_arr: this.labelValue,
    //         brand_id: this.brandInput
    //     }
    //     if (this.id) {
    //         dt.id = this.id
    //         dt.tag_arr = this.labelValue.map(item => item.t_id ? item.t_id : item)
    //         setsystemupdate(dt).then(res => {
    //             let { code, msg } = res
    //             if (code === 200) {
    //                 this.$message.success(msg)
    //                 this.form.name = '',
    //                     this.labelValue = [],
    //                     this.brandInput = ''
    //                 this.getList()
    //                 this.dialogVisible = false
    //             }

    //         })
    //     } else {
    //         setsystemadd(dt).then(res => {
    //             let { code, msg } = res
    //             if (code === 200) {
    //                 this.$message.success(msg)
    //                 this.form.name = '',
    //                     this.labelValue = [],
    //                     this.brandInput = ''
    //                 this.getList()
    //                 this.dialogVisible = false
    //             }

    //         })
    //     }
    // },
    // 关闭对话框
    removeID() {
      this.id = '';
      this.dialogVisible = false;
    },
    // 处理分页
    handleSizeChange(size) {
      this.page.limit = size;
      this.page.page = 1;
      this.getList();
    },
    handleCurrentChange(page) {
      this.page.page = page;
      this.getList();
    },
    // 序号
    indexMethod(index) {
      return this.page.limit * (this.page.page - 1) + 1 + index;
    },
  },
  created() {
    this.getList();
  },
};
</script>

<style lang="scss" scoped>
.bgc {
  background-color: #fff;
  padding: 20px;
  min-height: calc(100vh - 100px);
  position: relative;
}

.top {
  padding: 5px 5px 0;
  display: flex;
  align-items: center;
  justify-content: space-between;

  .el-button {
    margin-left: 10px;
    height: 35px;
  }

  .el-input {
    margin-left: 10px;
    width: 300px;
  }

  .el-select {
    width: 250px;
  }
}

.el-table-column {
  height: 50px;
}

::v-deep .el-dialog__body {
  padding: 10px 20px 10px 10px;
  margin: 10px;
  border-top: 1px solid #ccc;
}

::v-deep .el-dialog__header {
  padding: 10px 0 0 10px;
}

::v-deep .el-dialog__title {
  margin: 10px;
  font-size: 16px;
}
</style>

<!-- 分类下体系 -->
<template>
  <div>
    <div v-if="twoShow === 1">
      <div class="top">
        <div style="display: flex; align-items: center">
          <el-button type="primary" size="mini" @click="$parent.tab = 1">返回</el-button>
          <h3 style="margin-left: 15px">体系库</h3>
        </div>
        <div style="display: flex">
          <el-select
            v-model="value"
            placeholder="按状态筛选"
            size="medium"
            clearable
            @change="change"
          >
            <el-option
              v-for="item in options"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
          <el-input
            v-model="input"
            placeholder="按关键词进行查找"
            size="medium"
            @input="change1"
          ></el-input>
          <el-button
            type="primary"
            style="background-color: #29303e; border: 1px solid #29303e"
            size="small"
            @click="change1"
          >
            查询
          </el-button>
          <el-button
            type="primary"
            style="background-color: #29303e; border: 1px solid #29303e"
            size="small"
            @click="addSystem"
          >
            新建体系
          </el-button>
        </div>
      </div>
      <div>
        <el-table
          :data="tableData"
          style="width: 100%; color: #000"
          border
          :header-cell-style="{ background: '#F2F2F2' }"
          align="center"
        >
          <el-table-column
            type="index"
            :index="indexMethod"
            label="序号"
            width="100"
            align="center"
          ></el-table-column>
          <el-table-column prop="title" label="体系名称" align="center"></el-table-column>
          <el-table-column
            v-if="tabTag == 'TX4'"
            prop="adaptedThick"
            label="适配板厚"
            align="center"
          >
            <template #default="{ row }">
              <span>{{ row.adaptedThick == 0 ? '' : row.adaptedThick }}</span>
            </template>
          </el-table-column>
          <!-- <el-table-column prop="brand_name" label="所属品牌" width="180" align="center"> </el-table-column> -->
          <el-table-column prop="create_time" label="创建时间" align="center"></el-table-column>
          <!-- <el-table-column prop="tag_num" label="关联标签" align="center">
                        <template #default="{ row }">
                            <span @click="theLabelDetails(row.id)" style="color: #02a7f2;cursor: pointer">{{ row.tag_num
                            }}个</span>
                        </template>
                    </el-table-column> -->
          <el-table-column prop="middle_system_num" label="关联体系" align="center">
            <template #default="{ row }">
              <span style="color: #02a7f2; cursor: pointer" @click="relevanceSystem(row)">
                {{ row.middle_system_num }}个
              </span>
            </template>
          </el-table-column>
          <el-table-column prop="model_num" label="关联构件" align="center">
            <template #default="{ row }">
              <span style="color: #02a7f2; cursor: pointer" @click="check(row)">
                {{ row.model_num }}个
              </span>
            </template>
          </el-table-column>
          <!-- <el-table-column prop="picture" label="图片" align="center">
                    <template #default="{ row }">
                        <el-image v-if="row.picture" style="width: 50px; height: 50px" :src="row.picture"
                            :preview-src-list="[row.picture]" fit="cover" />
                    </template>
                </el-table-column> -->
          <el-table-column prop="status_str" width="100" label="状态" align="center">
            <template #default="{ row }">
              <el-tag v-if="row.status_str === '使用中'">使用中</el-tag>
              <el-tag v-else class="ml-2" type="warning">停用中</el-tag>
            </template>
          </el-table-column>
          <el-table-column prop="address" label="操作" align="center" width="200">
            <template #default="{ row }">
              <el-button
                type="text"
                @click="changeStatus(row.id)"
                v-if="row.status_str === '使用中'"
              >
                停用
              </el-button>
              <el-button type="text" @click="changeStatus(row.id)" v-else>启用</el-button>
              <el-button type="text" @click="edit(row.id, row.picture)">编辑</el-button>
              <el-button type="text" @click="specification(row.id)">规格</el-button>
              <el-button type="text" @click="del(row.id)">删除</el-button>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div style="text-align: center; margin-top: 30px">
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="page.page"
          :page-sizes="[10, 30, 40, 50]"
          :page-size="page.limit"
          layout="total, sizes, prev, pager, next, jumper"
          :total="page.total"
        ></el-pagination>
      </div>
      <!-- 新建体系 -->
      <el-dialog
        :visible.sync="dialogVisible"
        width="500px"
        :before-close="removeID"
        title="新建体系"
        center
        :close-on-click-modal="false"
        style="padding: 0 !important"
      >
        <el-form
          :model="form"
          label-width="120px"
          label-position="left"
          v-loading="addSystemLoading"
        >
          <el-form-item label="体系名称">
            <el-input v-model="form.name" style="width: 320px" />
          </el-form-item>
          <el-form-item label="体系ICON编号">
            <el-input v-model="form.classIconNumber" style="width: 320px" />
          </el-form-item>
          <el-form-item label="分类编号">
            <el-input v-model="form.classifyNumber" style="width: 320px" />
          </el-form-item>
          <el-form-item label="公库品牌名">
            <el-input v-model="form.publicBrandName" style="width: 320px" />
          </el-form-item>
          <el-form-item label="公库供应链名">
            <el-input v-model="form.publicSupplyChainName" style="width: 320px" />
          </el-form-item>
          <el-form-item v-if="tabTag == 'TX4'" label="适配板厚">
            <el-input v-model="form.adaptedThick" style="width: 320px" />
          </el-form-item>
          <!-- <el-form-item label="包含标签">
                        <el-cascader v-model="labelValue" :options="options1" :props="props2" clearable filterable
                            @change="handleChange" style="width: 320px" />
                    </el-form-item> -->
          <!-- <el-form-item label="图片">
                    <el-upload :auto-upload="false" class="avatar-uploader" action="#" :show-file-list="false"
                        :on-change="beforeAvatarUpload">
                        <img style="width: 100%" v-if="imageUrl" :src="imageUrl" class="avatar" />
                        <div v-else class="avatar-uploader-icon"><i class="el-icon-plus"></i></div>
                    </el-upload>
                </el-form-item> -->
        </el-form>
        <template #footer>
          <span class="dialog-footer">
            <div style="padding: 0 10px">
              <el-button @click="removeID" style="width: 150px">返回</el-button>
              <el-button type="primary" @click="conserve" style="width: 150px">保存</el-button>
            </div>
          </span>
        </template>
      </el-dialog>
      <!-- <el-dialog title="提示" :visible.sync="labelDialogVisible" width="30%" center :close-on-click-modal="false">
                <el-table :data="data" style="width: 100%;" height="300" v-loading="loading2">
                    <el-table-column fixed="left" prop="tag_title" label="名称" width="180" align="left">
                    </el-table-column>
                    <el-table-column prop="tag_title" label="标签" width="180" align="left">
                        <template #default="{ row }">
                            <el-select size="mini" v-model="row.value" multiple placeholder="请选择" :disabled="row.flag">
                                <el-option v-for="item in row.children2" :key="item.id" :label="item.middle_tag_title"
                                    :value="item.middle_tag_id">
                                </el-option>
                            </el-select>
                        </template>
                    </el-table-column>
                    <el-table-column fixed="right" label="操作" align="center">
                        <template #default="{ row }">
                            <el-button v-if="row.flag" @click="row.flag = false" type="text">编辑</el-button>
                            <el-button v-else @click="associatedLabelEdit(row)" type="text">保存</el-button>
                        </template>
                    </el-table-column>
                </el-table>
                <span slot="footer" class="dialog-footer">
                    <el-button style="width: 100px;" type="primary" @click="labelDialogVisible = false">关 闭</el-button>
                </span>
            </el-dialog> -->
      <!-- 规格 -->
      <el-dialog title="规格" :visible.sync="specificationDialog" width="550px" center>
        <el-table :data="specificationList" style="width: 100%" height="300" v-loading="loading3">
          <el-table-column
            fixed="left"
            prop="model_name"
            label="名称"
            width="180"
            align="left"
          ></el-table-column>
          <el-table-column prop="AllSpec" label="规格" width="180" align="left">
            <template #default="{ row }">
              <el-select
                size="mini"
                v-model="row.BoundSpec"
                multiple
                placeholder="请选择"
                :disabled="row.flag"
              >
                <el-option
                  v-for="(item, index) in row.AllSpec"
                  :key="index"
                  :label="item.specs"
                  :value="item.specs"
                ></el-option>
              </el-select>
            </template>
          </el-table-column>
          <el-table-column fixed="right" label="操作" align="center">
            <template #default="{ row }">
              <!-- <el-button v-if="row.flag" @click="row.flag = false" type="text">编辑</el-button> -->
              <el-button @click="saveSpecification(row)" type="text">保存</el-button>
            </template>
          </el-table-column>
        </el-table>
        <span slot="footer" class="dialog-footer">
          <el-button style="width: 100px" type="primary" @click="specificationDialog = false">
            关 闭
          </el-button>
        </span>
      </el-dialog>
      <!-- 关联体系 -->
      <el-dialog title="关联体系" :visible.sync="relevanceSystemDialog" width="500px" center>
        <el-select
          v-model="relevanceSystemData"
          multiple
          placeholder="请选择"
          v-loading="loading4"
          style="margin: 0 0 20px 90px; width: 300px"
        >
          <el-option
            v-for="item in relevanceSystemList"
            :key="item.id"
            :label="item.title"
            :value="item.id"
          ></el-option>
        </el-select>
        <span slot="footer" class="dialog-footer">
          <el-button
            style="width: 100px; margin-right: 20px"
            @click="relevanceSystemDialog = false"
          >
            取消
          </el-button>
          <el-button style="width: 100px" @click="relevanceSystemEdit" type="primary">
            保存
          </el-button>
        </span>
      </el-dialog>
    </div>
    <div v-if="twoShow === 2">
      <relevancyComponent
        :component="component"
        @returnTagList="returnTagList"
      ></relevancyComponent>
    </div>
  </div>
</template>

<script>
import {
  getsystemlist,
  setsystemstatus,
  setsystemdel,
  // getcanselecttag,
  setsysteminfo,
  setsystemadd,
  setsystemupdate,
  // gettagmiddlelist,
  settagmiddlesave,
  // getmiddlemodellist,
  getcanselecttag2,
  getmiddlemodelspecificationlist,
  setmiddlemodelsave2,
  getoptionallist,
  getselectedlist,
  setmiddlesystemsave,
} from '@/common/js/systemList.js';
import relevancyComponent from './components/relevancyComponent';
export default {
  // 组件名称
  name: 'demo',
  // 组件参数 接收来自父组件的数据
  props: {
    tabID: {
      type: Number,
    },
    tabTag: {
      type: String,
    },
  },
  // 局部注册的组件
  components: { relevancyComponent },
  // 组件状态值
  data() {
    return {
      form: {
        name: '',
        adaptedThick: '',
        classIconNumber: '',
        publicBrandName: '',
        publicSupplyChainName: '',
        classifyNumber: '',
      },
      options: [
        {
          value: 1,
          label: '启用中',
        },
        {
          value: 0,
          label: '停用中',
        },
      ],
      value: '',
      input: '',
      tableData: [],
      dialogVisible: false,
      page: {
        page: 1,
        limit: 10,
        total: 0,
      },
      labelList: [],
      labelValue: [],
      // brandInput: '',
      id: '',
      addSystemLoading: false,
      // imageUrl: '',
      // img: null,
      arrListNew: {},
      labelDialogVisible: false,
      data: [],
      data2: [],
      system_id: '',
      loading2: false,
      twoShow: 1,
      component: {},
      listOne: [],
      componentTitle: [],
      activeId: 0,
      options1: [],
      props2: {
        multiple: true,
        checkStrictly: true,
        label: 'value',
        value: 'address',
      },
      specificationDialog: false,
      specificationList: [],
      loading3: false,
      relevanceSystemDialog: false, // 关联体系对话框
      relevanceSystemData: [], // 已关联体系
      relevanceSystemList: [], // 可关联体系
      loading4: false,
    };
  },
  // 计算属性
  computed: {},
  // 侦听器
  watch: {
    dialogVisible(val) {
      if (val == false) {
        this.id = '';
      }
    },
  },
  // 组件方法
  methods: {
    // beforeAvatarUpload(file) {
    //     this.img = file.raw;
    //     this.imageUrl = URL.createObjectURL(file.raw);
    // },
    // 列表详情
    getList() {
      let dt = {};
      dt.page = this.page.page;
      dt.limit = this.page.limit;
      dt.status = this.value;
      dt.keywords = this.input;
      dt.classify_id = this.tabID;
      getsystemlist(dt).then((res) => {
        let {
          code,
          result: { data, total },
        } = res;
        if (code === 200) {
          this.tableData = data;
          this.page.total = total;
        }
      });
    },
    getTagList() {
      getcanselecttag2().then((res) => {
        if (res.code == 200) {
          res.result.forEach((item) => {
            if (item.children) {
              item.children.forEach((v) => {
                v.value = v.specs;
                v.address = v.specs;
              });
            }
          });
          this.options1 = res.result;
        }
      });
    },
    // 状态筛选
    change() {
      this.page.page = 1;
      this.getList();
    },
    // 输入关键词搜索
    change1() {
      this.page.page = 1;
      this.getList();
    },
    // 修改状态
    changeStatus(id) {
      let dt = { id };
      setsystemstatus(dt).then((res) => {
        let { code, msg } = res;
        if (code === 200) {
          this.getList();
          this.$message.success(msg);
        }
      });
    },
    // 删除
    del(id) {
      setsystemdel({ id }).then((res) => {
        let { code, msg } = res;
        if (code === 200) {
          this.$message.success(msg);
          this.getList();
        }
      });
    },
    // 编辑
    // edit(id, img) {
    edit(id) {
      this.dialogVisible = true;
      this.addSystemLoading = true;
      this.id = id;
      this.addSystem();
      // this.imageUrl = img;
      // this.img = img;
      setsysteminfo({ id }).then((res) => {
        let {
          code,
          result: {
            title,
            adaptedThick,
            classIconNumber,
            publicBrandName,
            publicSupplyChainName,
            classifyNumber,
          },
        } = res;
        if (code === 200) {
          this.form.name = title;
          this.form.adaptedThick = adaptedThick == 0 ? '' : adaptedThick;
          this.form.classIconNumber = classIconNumber;
          this.form.publicBrandName = publicBrandName;
          this.form.publicSupplyChainName = publicSupplyChainName;
          this.form.classifyNumber = classifyNumber;
          // this.labelValue = middle_tag.map(item => item.t_id);
          // middle_tag.forEach(item => {
          //     if (item.t_specs.length) {
          //         item.t_specs.forEach(v => {
          //             this.labelValue.push([item.t_id, v])
          //         })
          //     }
          // });
          // this.arrListNew = middle_tag;
          // this.brandInput = brand_id === 0 ? '' : brand_id;
        }
        this.addSystemLoading = false;
      });
    },
    // 新建体系
    addSystem() {
      this.form = {
        name: '',
        adaptedThick: '',
        classIconNumber: '',
        publicBrandName: '',
        publicSupplyChainName: '',
        classifyNumber: '',
      };
      // this.labelValue = [];
      // this.brandInput = '';
      this.dialogVisible = true;
      this.addSystemLoading = true;
      getcanselecttag2().then((res) => {
        if (res.code == 200) {
          res.result.forEach((item) => {
            if (item.children) {
              item.children.forEach((v) => {
                v.value = v.specs;
                v.address = v.specs;
              });
            }
          });
          this.options1 = res.result;
          this.addSystemLoading = false;
        }
      });
      // getcanselecttag().then(res => {
      //     console.log(res);
      //     let { code, result } = res;
      //     if (code === 200) {
      //         this.labelList = result;
      //         this.addSystemLoading = false;
      //     }
      // });
    },
    // handleChange() {
    //     this.labelValue.forEach(item => {
    //         if (item.length == 2 && item[1] != '') {
    //             this.labelValue.push(new Proxy([item[0]], {}))
    //         }
    //     })
    // },
    // 保存
    conserve() {
      // if (!this.form.name) {
      //     this.$message.warning('请输入体系名称')
      //     return
      // }
      let dt = {
        title: this.form.name,
        adaptedThick: this.form.adaptedThick,
        classIconNumber: this.form.classIconNumber,
        publicBrandName: this.form.publicBrandName,
        publicSupplyChainName: this.form.publicSupplyChainName,
        classifyNumber: this.form.classifyNumber,
        classify_id: this.tabID,
        tag_arr: [],
        spec_arr: [],
      };
      // if (this.labelValue.length) {
      //     this.labelValue.forEach((item) => {
      //         if (item !== undefined) {
      //             // console.log(item);
      //             if (item.length == 1) {
      //                 dt.tag_arr.push(item[0])
      //             }
      //             if (!item.length) {
      //                 dt.tag_arr.push(item)
      //             }
      //             if (item.length == 2 && item[1] != '') {
      //                 this.options1.forEach(k => {
      //                     if (k.address == item[0]) {
      //                         k.children.forEach((v) => {
      //                             if (v.specs == item[1]) {
      //                                 dt.spec_arr.push(v)
      //                             }
      //                         })
      //                     }
      //                 })
      //             }
      //         }

      //     })
      // }
      if (this.id) {
        // if (this.img != this.imageUrl) {
        //     dt.picture = this.img
        // }
        dt.id = this.id;
        setsystemupdate({ reqData: JSON.stringify(dt) }).then((res) => {
          let { code, msg } = res;
          if (code === 200) {
            this.$message.success(msg);
            this.form = {
              name: '',
              adaptedThick: '',
              classIconNumber: '',
              publicBrandName: '',
              publicSupplyChainName: '',
              classifyNumber: '',
            };
            // this.labelValue = [];
            // this.brandInput = '';
            this.getList();
            this.dialogVisible = false;
          }
        });
      } else {
        // dt.picture = this.img
        setsystemadd({ reqData: JSON.stringify(dt) }).then((res) => {
          let { code, msg } = res;
          if (code === 200) {
            this.$message.success(msg);
            this.form = {
              name: '',
              adaptedThick: '',
              classIconNumber: '',
              publicBrandName: '',
              publicSupplyChainName: '',
              classifyNumber: '',
            };
            // this.labelValue = [];
            // this.brandInput = '';
            this.getList();
            this.dialogVisible = false;
          }
        });
      }
    },
    // 规格列表
    specification(id) {
      this.specificationList = [];
      this.specificationDialog = true;
      this.loading3 = true;
      this.system_id = id;
      getmiddlemodelspecificationlist({ system_id: id }).then((res) => {
        if (res.code == 200) {
          res.result.forEach((item) => {
            if (item.BoundSpec) {
              item.BoundSpec.forEach((v, index) => {
                item.BoundSpec[index] = v.specs;
              });
            }
          });
          this.specificationList = res.result;
        }
        this.loading3 = false;
      });
    },
    //  规格编辑
    saveSpecification(data) {
      let dt = {
        system_classify_id: this.tabID,
        system_id: this.system_id,
        model_id: data.model_id,
        spec_arr: [],
      };
      data.BoundSpec.forEach((item) => {
        data.AllSpec.forEach((v) => {
          if (item == v.specs) {
            if (!v.id) {
              v.id = 0;
            }
            dt.spec_arr.push(v);
          }
        });
      });
      dt = JSON.stringify(dt);
      setmiddlemodelsave2({ reqData: dt }).then((res) => {
        if (res.code == 200) {
          this.$message.success(res.msg);
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    // 关联体系标签 - 列表
    // theLabelDetails(id) {
    //     this.loading2 = true
    //     this.system_id = id
    //     let dt = {
    //         classify_id: this.tabID,
    //         system_id: id
    //     }
    //     this.labelDialogVisible = true
    //     gettagmiddlelist(dt).then(res => {
    //         if (res.code == 200) {
    //             res.result.forEach(item => {
    //                 item.flag = true
    //                 item.value = []
    //                 item.children.forEach(v => {
    //                     item.value.push(v.middle_tag_id)
    //                 })
    //                 item.children2 = []
    //                 res.result.forEach(item1 => {
    //                     if (item.tag_id != item1.tag_id) {
    //                         item.children2.push({
    //                             "middle_tag_id": item1.tag_id,
    //                             "middle_tag_title": item1.tag_title,
    //                         })
    //                     }
    //                 })
    //             })
    //             this.data = res.result
    //         } else {
    //             this.$message.error(res.msg)
    //         }
    //         this.loading2 = false
    //     })
    // },
    // 关联体系标签 - 编辑
    associatedLabelEdit(data) {
      let dt = {
        classify_id: this.tabID,
        system_id: this.system_id,
        tag_id: data.tag_id,
        middle_ids: data.value,
      };
      settagmiddlesave(dt).then((res) => {
        if (res.code == 200) {
          data.flag = true;
          this.$message.success(res.msg);
          this.theLabelDetails(this.system_id);
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    // 关联体系列表
    relevanceSystem(data) {
      this.relevanceSystemList = [];
      this.relevanceSystemData = [];
      this.system_id = data.id;
      this.loading4 = true;
      this.relevanceSystemDialog = true;
      let dt = {
        classify_id: this.tabID,
        system_id: data.id,
      };
      getoptionallist(dt).then((res) => {
        if (res.code == 200) {
          this.relevanceSystemList = res.result;
        }
        getselectedlist(dt).then((res) => {
          if (res.code == 200) {
            res.result.forEach((item) => {
              this.relevanceSystemList.push(item);
              this.relevanceSystemData.push(item.id);
            });
          }
          this.loading4 = false;
        });
      });
    },
    // 关联体系编辑
    relevanceSystemEdit() {
      let dt = {
        classify_id: this.tabID,
        system_id: this.system_id,
        system_ids: this.relevanceSystemData,
      };
      setmiddlesystemsave(dt).then((res) => {
        if (res.code == 200) {
          this.relevanceSystemDialog = false;
          this.getList();
          this.$message.success(res.msg);
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    // 关闭对话框
    removeID() {
      this.id = '';
      this.form.adaptedThick = '';
      this.dialogVisible = false;
    },
    check(row) {
      this.loading = true;
      this.component = row;
      this.component.system_classify_id = this.tabID;
      this.twoShow = 2;
    },
    switchover(data) {
      this.activeId = data.id;
      this.listOne = data.model;
    },
    // 返回
    returnTagList() {
      this.twoShow = 1;
      this.getList();
      this.component = {};
      this.listOne = [];
      this.componentTitle = [];
    },
    // 处理分页
    handleSizeChange(size) {
      this.page.limit = size;
      this.page.page = 1;
      this.getList();
    },
    handleCurrentChange(page) {
      this.page.page = page;
      this.getList();
    },
    // 序号
    indexMethod(index) {
      return this.page.limit * (this.page.page - 1) + 1 + index;
    },
  },
  // 以下是生命周期钩子   注：没用到的钩子请自行删除
  /**
   * 在实例初始化之后，组件属性计算之前，如data属性等
   */
  beforeCreate() {},
  /**
   * 组件实例创建完成，属性已绑定，但DOM还未生成，$ el属性还不存在
   */
  created() {
    this.getList();
  },
  /**
   * 在挂载开始之前被调用：相关的 render 函数首次被调用。
   */
  beforeMount() {},
  /**
   * el 被新创建的 vm.$ el 替换，并挂载到实例上去之后调用该钩子。
   * 如果 root 实例挂载了一个文档内元素，当 mounted 被调用时 vm.$ el 也在文档内。
   */
  mounted() {},
  /**
   * 数据更新时调用，发生在虚拟 DOM 重新渲染和打补丁之前。
   * 你可以在这个钩子中进一步地更改状态，这不会触发附加的重渲染过程。
   */
  beforeUpdate() {},
  /**
   * 由于数据更改导致的虚拟 DOM 重新渲染和打补丁，在这之后会调用该钩子。
   * 当这个钩子被调用时，组件 DOM 已经更新，所以你现在可以执行依赖于 DOM 的操作。
   */
  updated() {},
};
</script>

<style lang="scss" scoped>
.avatar-uploader {
  border: 1px solid #bbb;
  display: inline-block;
  min-width: 100px;
  text-align: center;
}

::v-deep .el-dialog__body {
  padding-bottom: 0 !important;
  margin-bottom: 0 !important;
}

.top {
  padding: 5px 5px 0;
  display: flex;
  align-items: center;
  justify-content: space-between;

  .el-button {
    margin-left: 10px;
    height: 35px;
  }

  .el-input {
    margin-left: 10px;
    width: 300px;
  }

  .el-select {
    width: 250px;
  }
}

.el-table-column {
  height: 50px;
}

.custom-tree-node {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 14px;
  padding-right: 8px;
}

.item {
  width: 200px;
  margin: 0 10px;
  line-height: 35px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

::v-deep .el-dialog__body {
  padding: 10px 20px 10px 10px;
  margin: 10px;
  // border-top: 1px solid #ccc;
  border: none !important;
}

::v-deep .el-dialog__header {
  padding: 10px 0 0 10px;
}

::v-deep .el-dialog__title {
  margin: 10px;
  font-size: 16px;
}

::v-deep .el-table__fixed-right::before,
::v-deep .el-table__fixed::before,
::v-deep .el-table::before {
  display: none;
}

::v-deep .el-card__body {
  padding: 0;
}

.btn {
  display: inline-block;
  padding: 0.2em 0.8em;
  margin: 0.3em;
  vertical-align: middle;
  color: #409eff;
  font-weight: 400;
  // font-size: $font-size;
  line-height: 1.5;
  text-align: center;
  text-decoration: none;
  background-color: transparent;
  border: 1px solid #409eff;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  user-select: none;
  cursor: pointer;

  &:hover,
  &:focus,
  &:active,
  &.active {
    color: #fff;
    background-color: #409eff;
    border-color: #409eff;
  }
}
</style>
